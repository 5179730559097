import { CustomError } from "../../../utils/custom-error";
import ErrorPage from "../../shared/ErrorPage/ErrorPage";
import WrapperPage from "../../shared/WrapperPage/WrapperPage";

const BrowserErrorPage = (): JSX.Element => {
  return (
    <WrapperPage>
      <ErrorPage
        error={
          new CustomError(
            "Your browser is not supported! Please use a recent version of Google Chrome for desktop. Mobile devices are not supported.",
          )
        }
      />
    </WrapperPage>
  );
};

export default BrowserErrorPage;
