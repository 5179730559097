import { Box, Typography } from "@material-ui/core";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import {
  selectInterviewStatus,
  selectRecordingInProgress,
  selectUploadInProgress,
} from "../../../redux/interview/interview.selectors";
import { getVideoHeaderContent } from "../../../utils/get-video-header-content";
import useStyles from "./VideoHeader.styles";

export const VideoHeader = (): JSX.Element => {
  const status = useSelector(selectInterviewStatus);
  const recordingInProgress = useSelector(selectRecordingInProgress);
  const uploadInProgress = useSelector(selectUploadInProgress);

  const videoHeader = useMemo(
    () =>
      getVideoHeaderContent({ status, recordingInProgress, uploadInProgress }),
    [recordingInProgress, status, uploadInProgress],
  );

  const styles = useStyles({
    recordingInProgress,
    status,
  });

  return (
    <Box marginBottom="1rem">
      <Typography className={styles.header} variant="h3" align="center">
        {videoHeader}
      </Typography>
    </Box>
  );
};
