import { createStyles, makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    uploadMessageText: {
      marginBottom: theme.spacing(2),
      color: theme.palette.success.main,
    },
  }),
);

export default useStyles;
