import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { logout } from "../../../redux/auth/auth.slice";

const LogoutPage = (): null => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(logout());
  });

  return null;
};

export default LogoutPage;
