import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  link: {
    color: "blue",
    textDecoration: "underline",
  },
});

export default useStyles;
