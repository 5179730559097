import { Box, Card, CardContent, Grid, Typography } from "@material-ui/core";
import { Rating } from "@material-ui/lab";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteApplication,
  updateApplication,
} from "../../../redux/reviewer/reviewer.actions";
import { selectReviewerAsyncState } from "../../../redux/reviewer/reviewer.selectors";
import { Application } from "../../../redux/reviewer/reviewer.types";
import { ConfirmModalButton } from "../../shared/ConfirmModalButton/ConfirmModalButton";

interface Props {
  application: Application;
}

export const ApplicationInfoCard = ({ application }: Props): JSX.Element => {
  const { doStatus } = useSelector(selectReviewerAsyncState);

  const convertDate = useMemo(() => {
    if (!application || !application.createdAt) {
      return "unknown";
    }
    return new Date(application.createdAt).toLocaleString();
  }, [application]);

  const [rating, setRating] = useState(application?.rating || "0");
  useEffect(() => {
    setRating(application.rating);
  }, [application]);

  const dispatch = useDispatch();
  const handleVideoRatingChange = (_: unknown, rating: number | null) => {
    // istanbul ignore next: type guard
    if (rating === null) {
      return;
    }
    dispatch(
      updateApplication({
        id: application.id,
        update: { rating: rating.toString() },
      }),
    );
  };

  const handleDeleteClick = () => {
    dispatch(deleteApplication(application.id));
  };

  return (
    <Grid item xs={12} sm={8} md={6}>
      <Card>
        <CardContent>
          <Typography variant="h5">{application.name}</Typography>
          <Typography variant="body1">
            Email:{" "}
            <a href={`mailto:${application.email}`}>{application.email}</a>
          </Typography>
          <Typography variant="body1">Timestamp: {convertDate}</Typography>
          <Box marginY="0.5rem">
            <Rating
              name={`rating-${application.name}`}
              size="large"
              precision={0.5}
              value={parseFloat(rating)}
              onChange={handleVideoRatingChange}
              disabled={doStatus.pending}
            />
          </Box>
          <ConfirmModalButton
            actionName="Delete"
            dialogContent="Are you sure you want to delete this application? This action cannot be undone!"
            variant="contained"
            color="danger"
            handleConfirmClick={handleDeleteClick}
          />
        </CardContent>
      </Card>
    </Grid>
  );
};
