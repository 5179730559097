import { combineReducers } from "redux";
import { createTransform, PersistConfig, persistReducer } from "redux-persist";
import storage from "redux-persist/es/storage";
import authReducer from "./auth/auth.slice";
import interviewReducer, {
  initialInterviewState,
} from "./interview/interview.slice";
import { AsyncState, defaultAsyncState } from "./redux.helpers";
import reviewerReducer from "./reviewer/reviewer.slice";

export const blacklistTransform = createTransform(
  null,
  (state, key) => {
    switch (key) {
      case "interview": {
        return { ...initialInterviewState };
      }
      default: {
        return { ...(state as AsyncState), ...defaultAsyncState };
      }
    }
  },
  {
    whitelist: ["auth", "interview", "reviewer"],
  },
);

const persistConfig: PersistConfig<RootState> = {
  key: "root",
  storage,
  transforms: [blacklistTransform],
};

const _rootReducer = combineReducers({
  auth: authReducer,
  interview: interviewReducer,
  reviewer: reviewerReducer,
});

export type RootState = ReturnType<typeof _rootReducer>;

export const rootReducer = persistReducer(persistConfig, _rootReducer);
