import { AppBar, Box, Link, Typography } from "@material-ui/core";
import { useSelector } from "react-redux";
import { Link as RouterLink } from "react-router-dom";
import { selectIsLoggedIn } from "../../../redux/auth/auth.selectors";
import useStyles from "./Footer.styles";

export const Footer = (): JSX.Element => {
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const styles = useStyles();
  const year = new Date().getFullYear();

  return (
    <Box marginTop="auto">
      <Box marginTop="3rem">
        <AppBar position="static">
          <Box marginTop="0.5rem">
            <Typography variant="body1" align="center">
              <Link component={RouterLink} to="/" className={styles.link}>
                Home
              </Link>
              <span> | </span>
              <Link
                component={RouterLink}
                underline="hover"
                to="/reviewer"
                className={styles.link}>
                Reviewer
              </Link>
              {isLoggedIn && <span> | </span>}
              {isLoggedIn && (
                <Link
                  component={RouterLink}
                  underline="hover"
                  to="/reviewer/logout"
                  className={styles.link}>
                  Logout
                </Link>
              )}
            </Typography>
            <Box marginY="0.5rem">
              <Typography variant="body2" align="center">
                © {year} World Wide Wings Co., Ltd.
              </Typography>
            </Box>
          </Box>
        </AppBar>
      </Box>
    </Box>
  );
};
