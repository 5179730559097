import { Button, Grid, TextField, Typography } from "@material-ui/core";
import { useMemo } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { ProgressAlerts } from "../../../components/shared/ProgressAlerts/ProgressAlerts";
import { login } from "../../../redux/auth/auth.actions";
import { selectAuthAsyncStatus } from "../../../redux/auth/auth.selectors";
import { LoginCredentials } from "../../../redux/auth/auth.types";
import WrapperPage from "../../shared/WrapperPage/WrapperPage";

const LoginPage = (): JSX.Element => {
  const dispatch = useDispatch();

  const { doStatus } = useSelector(selectAuthAsyncStatus);

  const defaultValues = useMemo<LoginCredentials>(
    () => ({
      username: "",
      password: "",
    }),
    [],
  );

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ defaultValues });

  const onSubmit = (credentials: LoginCredentials) => {
    dispatch(login(credentials));
  };

  return (
    <WrapperPage>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container justifyContent="center" spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h3" align="center">
              Login
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              {...register("username", {
                required: {
                  value: true,
                  message: "Username is required.",
                },
              })}
              label="Username"
              type="text"
              variant="outlined"
              fullWidth
              error={!!errors.username}
              helperText={errors.username?.message}
              inputProps={{ "aria-label": "username" }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              {...register("password", {
                required: {
                  value: true,
                  message: "Password is required.",
                },
              })}
              label="Password"
              type="password"
              variant="outlined"
              fullWidth
              error={!!errors.password}
              helperText={errors.password?.message}
              inputProps={{ "aria-label": "password" }}
            />
          </Grid>
          <ProgressAlerts status={doStatus} />
          <Grid item>
            <Button
              type="submit"
              variant="contained"
              color="secondary"
              size="large">
              Submit
            </Button>
          </Grid>
        </Grid>
      </form>
    </WrapperPage>
  );
};

export default LoginPage;
