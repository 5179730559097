import { createSlice } from "@reduxjs/toolkit";
import { history } from "../../utils/history";
import {
  addAsyncMatchers,
  AsyncState,
  createMessageAction,
  redirectAfterTimeout,
} from "../redux.helpers";
import { login } from "./auth.actions";

export interface AuthState extends AsyncState {
  isLoggedIn: boolean;
}

const initialState: AuthState = {
  isLoggedIn: false,
  getStatus: {},
  doStatus: {},
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logout() {
      localStorage.clear();
      return initialState;
    },
    setIsLoggedIn: (state) => {
      state.isLoggedIn = true;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, createMessageAction("logging in"))
      .addCase(login.fulfilled, (state) => {
        state.isLoggedIn = true;
        redirectAfterTimeout("/reviewer")();
      });

    addAsyncMatchers(builder, "auth");
  },
});

export default authSlice.reducer;
export const { logout, setIsLoggedIn } = authSlice.actions;
