import {
  interviewStatus,
  InterviewStatus,
} from "../redux/interview/interview.types";

interface HeaderContentProps {
  recordingInProgress: boolean;
  uploadInProgress: boolean;
  status: InterviewStatus;
}

export const getVideoHeaderContent = ({
  recordingInProgress,
  uploadInProgress,
  status,
}: HeaderContentProps): string => {
  // keep in this order to make sure the right message shows at the right time
  if (status === interviewStatus.ready) {
    return "Instructions";
  }
  if (recordingInProgress) {
    return "Recording...";
  }
  if (status === interviewStatus.inProgress) {
    return "Prepare to Record";
  }
  if (uploadInProgress) {
    return "Uploading...";
  }
  if (status === interviewStatus.complete) {
    return "Upload Complete!";
  }
  return "";
};
