import { Button, Grid, TextField } from "@material-ui/core";
import { useMemo } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { submitForm } from "../../../redux/interview/interview.actions";
import { selectInterviewAsyncState } from "../../../redux/interview/interview.selectors";
import { ApplicantInfo } from "../../../redux/interview/interview.types";
import { ProgressAlerts } from "../../shared/ProgressAlerts/ProgressAlerts";
import { ApplicantInfoInstructions } from "../ApplicantInfoInstructions/ApplicantInfoInstructions";
import useStyles from "./ApplicantInfoForm.styles";

export const ApplicantInfoForm = (): JSX.Element => {
  const dispatch = useDispatch();
  const styles = useStyles();

  const { doStatus } = useSelector(selectInterviewAsyncState);

  const defaultValues = useMemo<ApplicantInfo>(
    () => ({
      name: "",
      email: "",
    }),
    [],
  );

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ defaultValues });

  const onSubmit = (info: ApplicantInfo) => {
    dispatch(submitForm(info));
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container direction="column" alignItems="center">
        <TextField
          {...register("name", {
            required: {
              value: true,
              message: "Full Name is required.",
            },
            maxLength: {
              value: 48,
              message: "Full Name must be shorter than 48 letters.",
            },
          })}
          label="Full Name"
          type="text"
          className={styles.formInput}
          variant="outlined"
          error={!!errors.name}
          helperText={errors.name?.message}
          inputProps={{ "aria-label": "name" }}
        />
        <TextField
          {...register("email", {
            required: {
              value: true,
              message: "Email Address is required.",
            },
            maxLength: {
              value: 48,
              message: "Email Address must be shorter than 48 letters.",
            },
          })}
          label="Email"
          type="email"
          className={styles.formInput}
          variant="outlined"
          error={!!errors.email}
          helperText={errors.email?.message}
          inputProps={{ "aria-label": "email" }}
        />

        <ApplicantInfoInstructions />
        <ProgressAlerts status={doStatus} />
        <Button
          className={styles.submitButton}
          type="submit"
          variant="contained"
          color="secondary">
          Next
        </Button>
      </Grid>
    </form>
  );
};
