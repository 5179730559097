import { createStyles, makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      alignItems: "center",
    },
    header: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      [theme.breakpoints.down("xs")]: {
        fontSize: theme.typography.h5.fontSize,
      },
    },
  }),
);

export default useStyles;
