import * as Sentry from "@sentry/react";
import { useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import Webcam from "react-webcam";
import { VideoContext } from "../../../contexts/VideoContext";
import SpinnerPage from "../../../pages/shared/SpinnerPage/SpinnerPage";
import {
  selectInterviewStatus,
  selectRecordingInProgress,
} from "../../../redux/interview/interview.selectors";
import {
  setInterviewError,
  setInterviewStatus,
} from "../../../redux/interview/interview.slice";
import { interviewStatus } from "../../../redux/interview/interview.types";
import useStyles from "./WebcamArea.styles";

export const WebcamArea = (): JSX.Element => {
  const status = useSelector(selectInterviewStatus);
  const recordingInProgress = useSelector(selectRecordingInProgress);

  const { webcamRef } = useContext(VideoContext);

  const videoConstraints: MediaTrackConstraints = {
    facingMode: "user",
    width: 360,
    height: 360,
    frameRate: 15,
  };
  const audioConstraints: MediaTrackConstraints = {
    sampleSize: 8,
    channelCount: 1,
  };

  const dispatch = useDispatch();

  const handleUserMedia = () => {
    dispatch(setInterviewStatus(interviewStatus.ready));
  };

  const handleUserMediaError = (error: string | DOMException) => {
    let message = "";
    const originalMessage = typeof error === "string" ? error : error.message;

    switch (originalMessage) {
      case "Permission denied": {
        message =
          "Failed to get webcam permission! Please check your settings, reload the page, and try again.";
      }
    }

    if (!message && process.env.NODE_ENV === "production") {
      Sentry.captureException(error);
    }

    dispatch(setInterviewError(message));
  };

  const styles = useStyles({
    recordingInProgress,
    status,
  });

  return (
    <>
      {status < interviewStatus.ready && (
        <SpinnerPage message="Preparing webcam..." />
      )}
      {/* need to render blank webcam area to trigger onUserMedia */}
      <Webcam
        className={styles.webcam}
        ref={webcamRef}
        videoConstraints={videoConstraints}
        audioConstraints={audioConstraints}
        onUserMedia={handleUserMedia}
        onUserMediaError={handleUserMediaError}
      />
    </>
  );
};
