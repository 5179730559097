import {
  ActionReducerMapBuilder,
  AnyAction,
  createSelector,
  OutputSelector,
  PayloadAction,
} from "@reduxjs/toolkit";
import { RootState } from "./root.reducer";
import { history } from "../utils/history";

export interface AsyncStatus {
  pending?: boolean;
  pendingMessage?: string;
  success?: boolean;
  successMessage?: string;
  error?: boolean;
  errorMessage?: string;
  warning?: boolean;
  warningMessage?: string;
}

export interface AsyncState {
  getStatus: AsyncStatus;
  doStatus: AsyncStatus;
}

export const defaultAsyncState: AsyncState = {
  getStatus: {},
  doStatus: {},
};

type AsyncStateSelector = (state: RootState) => AsyncState;

export interface MessageAction extends PayloadAction<unknown> {
  meta: {
    message?: string;
    arg: unknown;
    requestId: string;
    requestStatus: string;
  };
}
export type PayloadMessageAction<T> = MessageAction & PayloadAction<T>;

const isGetAction = (action: AnyAction): boolean => action.type.includes("get");
const isDoAction = (action: AnyAction): boolean => action.type.includes("do");

const isPendingAction = (action: AnyAction): boolean =>
  action.type.endsWith("/pending");
const isFulfilledAction = (action: AnyAction): boolean =>
  action.type.endsWith("/fulfilled");
const isRejectedAction = (action: AnyAction): boolean =>
  action.type.endsWith("/rejected");

const matchesPrefix = (action: AnyAction, prefix: string): boolean =>
  action.type.includes(prefix);

export const isPendingGetAction = (
  action: AnyAction,
  prefix: string,
): boolean =>
  matchesPrefix(action, prefix) &&
  isGetAction(action) &&
  isPendingAction(action);
export const isFulfilledGetAction = (
  action: AnyAction,
  prefix: string,
): boolean =>
  matchesPrefix(action, prefix) &&
  isGetAction(action) &&
  isFulfilledAction(action);
export const isRejectedGetAction = (
  action: AnyAction,
  prefix: string,
): boolean =>
  matchesPrefix(action, prefix) &&
  isGetAction(action) &&
  isRejectedAction(action);

export const isPendingDoAction = (action: AnyAction, prefix: string): boolean =>
  matchesPrefix(action, prefix) &&
  isDoAction(action) &&
  isPendingAction(action);
export const isFulfilledDoAction = (
  action: AnyAction,
  prefix: string,
): boolean =>
  matchesPrefix(action, prefix) &&
  isDoAction(action) &&
  isFulfilledAction(action);
export const isRejectedDoAction = (
  action: AnyAction,
  prefix: string,
): boolean =>
  matchesPrefix(action, prefix) &&
  isDoAction(action) &&
  isRejectedAction(action);

export const addAsyncMatchers = (
  builder: ActionReducerMapBuilder<AsyncState>,
  prefix: string,
): void => {
  builder
    .addMatcher(
      (action) => isPendingGetAction(action, prefix),
      (state, action) => {
        state.getStatus = {
          pendingMessage: action.meta.message,
          pending: true,
        };
      },
    )
    .addMatcher(
      (action) => isFulfilledGetAction(action, prefix),
      (state, action) => {
        state.getStatus = {
          success: true,
          successMessage: action.meta.message,
        };
      },
    )
    .addMatcher(
      (action) => isRejectedGetAction(action, prefix),
      (state, action) => {
        state.getStatus = { error: true, errorMessage: action.error.message };
      },
    )
    .addMatcher(
      (action) => isPendingDoAction(action, prefix),
      (state, action) => {
        state.doStatus = {
          pendingMessage: action.meta.message,
          pending: true,
        };
      },
    )
    .addMatcher(
      (action) => isFulfilledDoAction(action, prefix),
      (state, action) => {
        state.doStatus = { success: true, successMessage: action.meta.message };
      },
    )
    .addMatcher(
      (action) => isRejectedDoAction(action, prefix),
      (state, action) => {
        state.doStatus = { error: true, errorMessage: action.error.message };
      },
    );
};

export const resetGetStatus = (state: { getStatus: AsyncStatus }): void => {
  state.getStatus = {};
};
export const resetDoStatus = (state: { doStatus: AsyncStatus }): void => {
  state.doStatus = {};
};
export const asyncStatusResetActions = { resetGetStatus, resetDoStatus };

export const createMessageAction =
  (message: string) =>
  (_: unknown, action: MessageAction): void => {
    action.meta.message = message;
  };

export const createAsyncStateSelector = (
  selector: AsyncStateSelector,
): OutputSelector<RootState, AsyncState, (res: AsyncState) => AsyncState> =>
  createSelector(selector, (state) => ({
    doStatus: state.doStatus,
    getStatus: state.getStatus,
  }));

export const redirectAfterTimeout =
  (url: string, timeout = 0) =>
  (): void => {
    setTimeout(() => history.push(url), timeout);
  };

export const updateArrayElement = <T extends { id: string }>(
  array: T[],
  updatedElement: T,
): T[] =>
  array.map((originalElement) =>
    originalElement.id === updatedElement.id
      ? { ...originalElement, ...updatedElement }
      : originalElement,
  );

export const deleteArrayElement = <T extends { id: string }>(
  array: T[],
  elementToDelete: T | string,
): T[] =>
  array.filter((originalElement) =>
    typeof elementToDelete === "string"
      ? originalElement.id !== elementToDelete
      : originalElement.id !== elementToDelete.id,
  );
