import { CustomError } from "../../../utils/custom-error";
import ErrorPage from "../ErrorPage/ErrorPage";
import WrapperPage from "../WrapperPage/WrapperPage";

const NotFoundErrorPage = (): JSX.Element => {
  return (
    <WrapperPage>
      <ErrorPage error={new CustomError("page not found")} />
    </WrapperPage>
  );
};

export default NotFoundErrorPage;
