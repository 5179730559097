import { Button, Grid } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { setInterviewStatus } from "../../../redux/interview/interview.slice";
import { interviewStatus } from "../../../redux/interview/interview.types";
import useStyles from "./StartInterviewButton.styles";

export const StartInterviewButton = (): JSX.Element => {
  const dispatch = useDispatch();
  const handleClick = () => {
    dispatch(setInterviewStatus(interviewStatus.inProgress));
  };

  const styles = useStyles();

  return (
    <Grid item>
      <Button
        className={styles.startInterviewButton}
        variant="contained"
        color="secondary"
        onClick={handleClick}>
        Start
      </Button>
    </Grid>
  );
};
