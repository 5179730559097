import { Button, Grid, Typography } from "@material-ui/core";
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { VideoContext } from "../../../contexts/VideoContext";
import { getQuestions } from "../../../redux/interview/interview.actions";
import {
  selectCurrentQuestion,
  selectInterviewStatus,
  selectQuestions,
} from "../../../redux/interview/interview.selectors";
import {
  setCurrentQuestion,
  setInterviewStatus,
} from "../../../redux/interview/interview.slice";
import { interviewStatus } from "../../../redux/interview/interview.types";

interface Props {
  countdownLength?: number;
}

export const QuestionArea = ({
  countdownLength = process.env.NODE_ENV === "production" ? 15 : 5,
}: Props): JSX.Element => {
  const dispatch = useDispatch();

  const { startRecording, stopRecording } = useContext(VideoContext);

  const questions = useSelector(selectQuestions);
  const currentQuestion = useSelector(selectCurrentQuestion);
  const status = useSelector(selectInterviewStatus);

  const [timer, setTimer] = useState<number | null>(null);
  const [inCountdown, setInCountdown] = useState(false);

  const timerRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    // will not be necessary once questions are fetched from server
    if (questions.length === 0) {
      dispatch(getQuestions());
    }
  }, [dispatch, questions, questions.length]);

  const countDownTimer = useCallback(() => {
    // istanbul ignore else: type guard
    if (typeof timer === "number" && timer > 0) {
      setTimer(timer - 1);
    }
  }, [timer]);

  const cleanupTimer = () => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
      timerRef.current = null;
    }
  };

  const resetTimer = useCallback(() => {
    cleanupTimer();
    timerRef.current = setTimeout(() => {
      countDownTimer();
    }, 1000);
  }, [countDownTimer]);

  // clear and reinstantiate timer whenever inCountdown changes
  useEffect(() => {
    resetTimer();
  }, [inCountdown, resetTimer]);

  // handle navigation away from page
  const cleanup = useCallback(() => {
    cleanupTimer();
    stopRecording();
  }, [stopRecording]);

  // start timer once questions have been "fetched"
  useEffect(() => {
    if (questions.length > 0) {
      setTimer(countdownLength);
      setInCountdown(true);
    }
    return cleanup;
  }, [countdownLength, questions.length, cleanup]);

  // handle countdown end
  const handleCountdownTimerEnd = useCallback(() => {
    setTimer(questions[currentQuestion].length);
    resetTimer();
    setInCountdown(false);
    startRecording(currentQuestion);
  }, [questions, currentQuestion, startRecording, resetTimer]);

  useEffect(() => {
    if (inCountdown && timer === 0) {
      handleCountdownTimerEnd();
    }
  }, [questions, timer, inCountdown, handleCountdownTimerEnd]);

  // handle question timer end
  const handleQuestionTimerEnd = useCallback(() => {
    if (status === interviewStatus.complete) {
      return;
    }

    stopRecording();

    const nextIndex = currentQuestion + 1;

    dispatch(setCurrentQuestion(nextIndex));

    // when no next question, end interview
    if (!questions[nextIndex]) {
      dispatch(setInterviewStatus(interviewStatus.complete));
    }

    // otherwise, move to next question and restart countdown
    if (questions[nextIndex]) {
      setTimer(countdownLength);
      setInCountdown(true);
    }
  }, [
    countdownLength,
    dispatch,
    questions,
    currentQuestion,
    stopRecording,
    status,
  ]);

  useEffect(() => {
    if (!inCountdown && timer === 0) {
      handleQuestionTimerEnd();
    }
  });

  const timerText = useMemo(() => {
    if (!timer) {
      return "";
    }
    return timer > 1 ? `${timer} seconds` : `${timer} second`;
  }, [timer]);

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      data-testid="question-area">
      {questions.length > 0 && (
        <Grid container direction="column" alignItems="center" spacing={2}>
          {inCountdown ? (
            <Grid item>
              <Typography variant="body1" align="center">
                Recording will begin in: {timerText}.
              </Typography>
            </Grid>
          ) : (
            <Grid
              item
              container
              xs={12}
              justifyContent="center"
              alignItems="center"
              spacing={2}>
              <Grid item>
                <Typography variant="body1" align="center">
                  Recording... {timerText} left.
                </Typography>
              </Grid>
              <Grid item>
                <Button variant="contained" onClick={handleQuestionTimerEnd}>
                  next
                </Button>
              </Grid>
            </Grid>
          )}
          <Grid item>
            <Typography variant="body1" align="center">
              {questions[currentQuestion] && (
                <strong>
                  Question #{currentQuestion + 1}:{" "}
                  {questions[currentQuestion]["text"]}
                </strong>
              )}
            </Typography>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};
