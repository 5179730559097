import { createStyles, makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    link: {
      textDecoration: "none",
    },
    card: {
      "&:hover": {
        animationName: "$hoverLink",
        animationDuration: "0.5s",
        animationFillMode: "forwards",
      },
    },
    "@keyframes hoverLink": {
      to: {
        background: theme.palette.grey[200],
      },
    },
  }),
);

export default useStyles;
