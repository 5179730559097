import {
  Box,
  CircularProgress,
  Container,
  Typography,
} from "@material-ui/core";

interface Props {
  message?: string;
}

const SpinnerPage = ({ message }: Props): JSX.Element => {
  return (
    <Container>
      <Box
        height="50vw"
        display="flex"
        justifyContent="center"
        alignItems="center">
        <CircularProgress size="6rem" />
      </Box>
      {message && (
        <Box textAlign="center">
          <Typography variant="h4">{message}</Typography>
        </Box>
      )}
    </Container>
  );
};

export default SpinnerPage;
