import { createAsyncThunk } from "@reduxjs/toolkit";
import { customAxios } from "../../utils/axios";
import { validateResponse } from "../action.helpers";
import { RootState } from "../root.reducer";
import { Application } from "./reviewer.types";

export const getApplications = createAsyncThunk<
  Application[],
  void,
  { state: RootState }
>("reviewer/getMany", async () => {
  const response = await customAxios.get("/applications");
  validateResponse(response, "applications");

  return response.data.applications as Application[];
});

export const getApplication = createAsyncThunk<Application, string>(
  "reviewer/getOne",
  async (id) => {
    const response = await customAxios.get(`/applications/${id}`);
    validateResponse(response, "application");

    return response.data.application as Application;
  },
);

export const updateApplication = createAsyncThunk<
  Application,
  { id: string; update: Partial<Application> }
>("reviewer/doUpdateOne", async ({ id, update }) => {
  const response = await customAxios.patch(`/applications/${id}`, {
    ...update,
  });

  validateResponse(response, "application");

  return response.data.application as Application;
});

export const deleteApplication = createAsyncThunk<void, string>(
  "reviewer/doDeleteVideo",
  async (id) => {
    await customAxios.delete(`/applications/${id}`);
  },
);
