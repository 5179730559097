import { Typography } from "@material-ui/core";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { selectUploadInProgress } from "../../../redux/interview/interview.selectors";
import useStyles from "./UploadMessage.styles";

export const UploadMessage = (): JSX.Element => {
  const uploadInProgress = useSelector(selectUploadInProgress);

  const content = useMemo(() => {
    return uploadInProgress
      ? "Upload in progress... Please wait."
      : "Upload successful! You may now close this page.";
  }, [uploadInProgress]);

  const styles = useStyles();

  return (
    <Typography
      className={styles.uploadMessageText}
      variant="h6"
      align="center">
      {content}
    </Typography>
  );
};
