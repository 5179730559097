import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: "auto",
  },
  link: {
    color: theme.palette.primary.contrastText,
  },
}));

export default useStyles;
