import { createContext } from "react";
import { WebcamRef } from "../redux/interview/interview.types";

export interface VideoContextData {
  webcamRef: WebcamRef | null;
  startRecording: (questionIndex: number) => void;
  stopRecording: () => void;
}

// istanbul ignore next: fallback
export const videoContextDefaultData: VideoContextData = {
  webcamRef: null,
  startRecording: () => null,
  stopRecording: () => null,
};

export const VideoContext = createContext<VideoContextData>(
  videoContextDefaultData,
);
