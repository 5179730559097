import { Button, ButtonProps, Color } from "@material-ui/core";
import useStyles from "./CustomButton.styles";

const customColors = ["warning", "danger"] as const;
export type CustomColor = typeof customColors[number];
const isNotCustomColor = (
  color: CustomButtonColor,
): color is ButtonProps["color"] =>
  !customColors.includes(color as CustomColor);

export type CustomButtonColor = ButtonProps["color"] | Color | CustomColor;

export interface CustomButtonProps extends Omit<ButtonProps, "color"> {
  color?: CustomButtonColor;
}
export const CustomButton = ({
  color,
  ...otherProps
}: CustomButtonProps): JSX.Element => {
  const styles = useStyles({ color });
  return (
    <Button
      color={isNotCustomColor(color) ? color : undefined}
      className={styles.root}
      {...otherProps}
    />
  );
};
