import { Box, Card, Grid, Typography } from "@material-ui/core";
import { Rating } from "@material-ui/lab";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { ProgressAlerts } from "../../../components/shared/ProgressAlerts/ProgressAlerts";
import { getApplications } from "../../../redux/reviewer/reviewer.actions";
import {
  selectApplications,
  selectReviewerAsyncState,
} from "../../../redux/reviewer/reviewer.selectors";
import WrapperPage from "../../shared/WrapperPage/WrapperPage";
import useStyles from "./ReviewApplicationsPage.styles";

const ReviewApplicationsPage = (): JSX.Element => {
  const applications = useSelector(selectApplications);
  const { getStatus } = useSelector(selectReviewerAsyncState);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getApplications());
  }, [dispatch]);

  const styles = useStyles();

  return (
    <WrapperPage>
      <Grid container justifyContent="center" spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h3" align="center">
            Applications
          </Typography>
        </Grid>
        <ProgressAlerts status={getStatus} />
        <Grid item container justifyContent="center" spacing={1}>
          {applications.map((application) => (
            <Grid item key={application.id} xs={12} sm={8}>
              <Link to={`/reviewer/${application.id}`} className={styles.link}>
                <Card className={styles.card} data-testid="card">
                  <Box margin="1rem">
                    <Typography variant="h6">{application.name}</Typography>
                    <Rating
                      readOnly
                      size="large"
                      precision={0.5}
                      value={parseFloat(application.rating)}
                    />
                  </Box>
                </Card>
              </Link>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </WrapperPage>
  );
};

export default ReviewApplicationsPage;
