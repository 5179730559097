import { Question } from "../redux/interview/interview.types";

const questionLength = process.env.NODE_ENV === "development" ? 15 : 60;

export const betaQuestions: Question[] = [
  { text: "Why do you want to work with kids?", length: questionLength },
  {
    text: "What do you think is the best way to learn a second language?",
    length: questionLength,
  },
];
