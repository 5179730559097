import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import { createReduxEnhancer } from "@sentry/react";
import {
  FLUSH,
  PAUSE,
  PERSIST,
  persistStore,
  PURGE,
  REGISTER,
  REHYDRATE,
} from "redux-persist";
import { rootReducer } from "./root.reducer";

const sentryReduxEnhancer = createReduxEnhancer();

const middleware = getDefaultMiddleware({
  serializableCheck: {
    ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
  },
});

export const store = configureStore({
  reducer: rootReducer,
  middleware,
  enhancers: [sentryReduxEnhancer],
  devTools: process.env.NODE_ENV === "development",
});

export const persistor = persistStore(store);
