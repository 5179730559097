import { Snackbar } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { useEffect, useState } from "react";
import { AsyncStatus } from "../../../redux/redux.helpers";

interface Props {
  status: AsyncStatus;
  autoHideDuration?: number;
}

// currently invokes warning in StrictMode
export const ProgressPopup = ({
  status,
  autoHideDuration = 5000,
}: Props): JSX.Element => {
  const [successOpen, setSuccessOpen] = useState(!!status.successMessage);
  const [pendingOpen, setPendingOpen] = useState(!!status.pendingMessage);
  const [errorOpen, setErrorOpen] = useState(!!status.errorMessage);

  useEffect(() => {
    setSuccessOpen(!!status.successMessage);
    setPendingOpen(!!status.pendingMessage);
    setErrorOpen(!!status.errorMessage);
  }, [status]);
  const handleSuccessClose = () => setSuccessOpen(false);
  const handlePendingClose = () => setPendingOpen(false);
  const handleErrorClose = () => setErrorOpen(false);

  return (
    <>
      <Snackbar
        open={successOpen}
        autoHideDuration={autoHideDuration}
        onClose={handleSuccessClose}>
        <Alert onClose={handleSuccessClose} severity="success">
          Success: {status.successMessage}
        </Alert>
      </Snackbar>
      <Snackbar
        open={pendingOpen}
        autoHideDuration={autoHideDuration}
        onClose={handlePendingClose}>
        <Alert onClose={handlePendingClose} severity="info">
          Please wait: {status.pendingMessage}
        </Alert>
      </Snackbar>
      <Snackbar open={errorOpen} onClose={handleErrorClose}>
        <Alert onClose={handleErrorClose} severity="error">
          Error: {status.errorMessage}
        </Alert>
      </Snackbar>
    </>
  );
};
