import { createStyles, makeStyles, Theme } from "@material-ui/core";
import {
  interviewStatus,
  InterviewStatus,
} from "../../../redux/interview/interview.types";

interface Props {
  recordingInProgress: boolean;
  status: InterviewStatus;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    webcam: {
      border: ({ recordingInProgress, status }: Props) => {
        let color = theme.palette.primary.contrastText;
        if (recordingInProgress) {
          color = theme.palette.info.main;
        } else if (status === interviewStatus.inProgress) {
          color = theme.palette.warning.main;
        } else if (status === interviewStatus.complete) {
          color = theme.palette.success.main;
        }
        return `2px solid ${color}`;
      },
      borderRadius: "4px",
    },
  }),
);

export default useStyles;
