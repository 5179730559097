import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import { useState } from "react";
import { CustomButton, CustomButtonProps } from "../CustomButton/CustomButton";

interface Props extends CustomButtonProps {
  dialogTitle?: string;
  dialogContent: string;
  handleConfirmClick: () => void;
  actionName: string;
  keepOpen?: boolean;
}
export const ConfirmModalButton = ({
  dialogTitle,
  dialogContent,
  handleConfirmClick,
  actionName,
  keepOpen,
  ...otherProps
}: Props): JSX.Element => {
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const confirmClickWrapper = () => {
    handleConfirmClick();
    if (!keepOpen) {
      handleClose();
    }
  };

  return (
    <>
      <CustomButton
        onClick={handleClickOpen}
        data-testid="confirm-modal-open-button"
        {...otherProps}>
        {actionName}
      </CustomButton>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{dialogTitle || actionName}</DialogTitle>
        <DialogContent>
          <DialogContentText>{dialogContent}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            color="primary"
            data-testid="confirm-modal-cancel-button">
            cancel
          </Button>
          <Button
            onClick={confirmClickWrapper}
            data-testid="confirm-modal-confirm-button">
            {actionName}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
