import { makeStyles } from "@material-ui/core";
import { CustomButtonColor } from "./CustomButton";

interface Props {
  color: CustomButtonColor;
}

const useStyles = makeStyles(({ palette: { error, warning } }) => ({
  root: {
    color: ({ color }: Props) => {
      switch (color) {
        case "warning": {
          return warning.contrastText;
        }
        case "danger": {
          return error.contrastText;
        }
        default: {
          return "";
        }
      }
    },
    backgroundColor: ({ color }: Props) => {
      switch (color) {
        case "warning": {
          return warning.main;
        }
        case "danger": {
          return error.main;
        }
        default: {
          return "";
        }
      }
    },
    "&:hover": {
      backgroundColor: ({ color }: Props) => {
        switch (color) {
          case "warning": {
            return warning.dark;
          }
          case "danger": {
            return error.dark;
          }
          default: {
            return "";
          }
        }
      },
    },
  },
}));

export default useStyles;
