import { Box, Grid } from "@material-ui/core";
import { Alert, AlertProps } from "@material-ui/lab";
import { AsyncStatus } from "../../../redux/redux.helpers";

interface AlertWithPaddingProps extends AlertProps {
  padding?: string;
}

const AlertWithPadding = ({
  padding = "1rem",
  ...otherProps
}: AlertWithPaddingProps) => (
  <Box marginY={padding}>
    <Alert {...otherProps} />
  </Box>
);

interface Props {
  status: AsyncStatus;
  padding?: string;
}

export const ProgressAlerts = ({ status, padding }: Props): JSX.Element => {
  return (
    <Grid item xs={12} container justifyContent="center">
      <Grid item xs={12} md={6}>
        {status.pendingMessage && (
          <AlertWithPadding severity="info" padding={padding}>
            Please wait: {status.pendingMessage}
          </AlertWithPadding>
        )}
        {status.errorMessage && (
          <AlertWithPadding severity="error">
            Error: {status.errorMessage}
          </AlertWithPadding>
        )}
        {status.successMessage && (
          <AlertWithPadding severity="success">
            Success: {status.successMessage}
          </AlertWithPadding>
        )}
      </Grid>
    </Grid>
  );
};
