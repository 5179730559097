import { AppBar, Box, Toolbar, Typography } from "@material-ui/core";
import useStyles from "./Header.styles";

export const Header = (): JSX.Element => {
  const styles = useStyles();
  return (
    <Box marginBottom="1rem">
      <AppBar position="static" className={styles.appBar}>
        <Toolbar>
          <Typography variant="h4" className={styles.header}>
            WKF Interview System
          </Typography>
        </Toolbar>
      </AppBar>
    </Box>
  );
};
