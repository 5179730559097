import { Container, Typography } from "@material-ui/core";
import { ApplicantInfoForm } from "../../../components/interview/ApplicantInfoForm/ApplicantInfoForm";

const ApplicantInfoPage = (): JSX.Element => {
  return (
    <Container>
      <Typography variant="h3" align="center" gutterBottom>
        Applicant Info
      </Typography>
      <ApplicantInfoForm />
    </Container>
  );
};

export default ApplicantInfoPage;
