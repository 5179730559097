import { createSelector } from "@reduxjs/toolkit";
import { createAsyncStateSelector } from "../redux.helpers";
import { RootState } from "../root.reducer";

const selectReviewerState = (state: RootState) => state.reviewer;

export const selectApplications = createSelector(
  selectReviewerState,
  (reviewer) => reviewer.applications,
);

export const selectCurrentApplication = createSelector(
  selectReviewerState,
  (reviewer) => {
    if (!reviewer.currentApplication) {
      return reviewer.currentApplication;
    }

    const sortedVideos = [...reviewer.currentApplication.videos].sort(
      (a, b) => a.questionIndex - b.questionIndex,
    );

    return {
      ...reviewer.currentApplication,
      videos: sortedVideos,
    };
  },
);

export const selectReviewerAsyncState =
  createAsyncStateSelector(selectReviewerState);
