import { Grid, Typography, Box } from "@material-ui/core";

export const VideoInstructions = (): JSX.Element => {
  return (
    <Grid item xs={5} data-testid="video-instructions">
      <Box marginBottom="1rem">
        <Typography align="center" variant="body1" color="textSecondary">
          You will have 15 seconds to read each question, then recording will
          automatically begin. Please answer each question in the alloted time.
          If you finish answering a question early, you may click the{" "}
          <strong>Next</strong> button to immediately proceed to the next
          question. Recordings will be uploaded automatically.
        </Typography>
      </Box>
      <Box marginBottom="1rem">
        <Typography align="center" variant="body1" color="textSecondary">
          The interview will begin once you click the <strong>Start</strong>{" "}
          button below.
        </Typography>
      </Box>
    </Grid>
  );
};
