import { Grid } from "@material-ui/core";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Prompt } from "react-router";
import { QuestionArea } from "../../../components/interview/QuestionArea/QuestionArea";
import { StartInterviewButton } from "../../../components/interview/StartInterviewButton/StartInterviewButton";
import { UploadMessage } from "../../../components/interview/UploadMessage/UploadMessage";
import { VideoArea } from "../../../components/video/VideoArea/VideoArea";
import { VideoInstructions } from "../../../components/video/VideoInstructions/VideoInstructions";
import { VideoContextProvider } from "../../../contexts/VideoContextProvider";
import {
  selectBrowserIsInvalid,
  selectInterviewError,
  selectInterviewStatus,
} from "../../../redux/interview/interview.selectors";
import { checkIfBrowserIsValid } from "../../../redux/interview/interview.slice";
import { interviewStatus } from "../../../redux/interview/interview.types";
import { CustomError } from "../../../utils/custom-error";
import WrapperPage from "../../shared/WrapperPage/WrapperPage";
import ApplicantInfoPage from "../ApplicantInfoPage/ApplicantInfoPage";
import BrowserErrorPage from "../BrowserErrorPage/BrowserErrorPage";

const InterviewPage = (): JSX.Element => {
  const status = useSelector(selectInterviewStatus);
  const error = useSelector(selectInterviewError);
  const browserIsInvalid = useSelector(selectBrowserIsInvalid);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(checkIfBrowserIsValid());
  }, [dispatch]);

  if (status === interviewStatus.error) {
    throw new CustomError(error);
  }

  if (browserIsInvalid) {
    return <BrowserErrorPage />;
  }

  return (
    <WrapperPage>
      {status === interviewStatus.new && <ApplicantInfoPage />}
      {status > interviewStatus.new && (
        <VideoContextProvider>
          <Grid container direction="column" alignItems="center" spacing={2}>
            <VideoArea />
            {status === interviewStatus.ready && (
              <>
                <VideoInstructions />
                <StartInterviewButton />
              </>
            )}
            {status === interviewStatus.inProgress && <QuestionArea />}
            {status === interviewStatus.complete && <UploadMessage />}
            <Prompt
              when={
                status >= interviewStatus.inProgress &&
                status < interviewStatus.complete
              }
              message="Are you sure you want to leave this page? Your data will not be saved!"
            />
          </Grid>
        </VideoContextProvider>
      )}
    </WrapperPage>
  );
};

export default InterviewPage;
