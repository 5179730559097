import axios, { AxiosInstance, AxiosResponse } from "axios";
import { history } from "./history";

export interface ExtendedInstance extends AxiosInstance {
  updateToken: (token: string) => void;
  getTokenFromLocalStorage: () => void;
}

export const createAxiosInstance = (): ExtendedInstance => {
  const instance = axios.create({
    baseURL:
      process.env.NODE_ENV === "production"
        ? process.env.REACT_APP_API_URL
        : process.env.REACT_APP_DEV_API_URL,
  }) as ExtendedInstance;

  instance.defaults.validateStatus = () => true;

  instance.interceptors.response.use(
    async (response) => await validateStatus(response),
  );

  instance.updateToken = (token: string): void => {
    instance.defaults.headers.common.authorization = `Bearer ${token}`;
  };

  instance.getTokenFromLocalStorage = () => {
    const token = localStorage.getItem("jwt");
    if (token) {
      instance.updateToken(token);
    }
  };

  instance.getTokenFromLocalStorage();
  return instance;
};

export const validateStatus = async (
  response: AxiosResponse,
): Promise<AxiosResponse> => {
  if (!response.status || response.status === 500) {
    throw new Error("something went wrong");
  }
  switch (response.status) {
    case 400: {
      throw new Error(
        typeof response.data.message === "object"
          ? response.data.message[0]
          : response.data.message,
      );
    }
    case 401: {
      throw new Error(response.data.message || "invalid credentials");
    }
    case 404: {
      history.replace("/not-found");
      break;
    }
    case 413: {
      // GAE payload size error
      // TODO: catch where applicants can actually see it
      throw new Error(
        `Failed to upload video(s). Make sure to record using Google Chrome.`,
      );
    }
  }
  return response;
};

export const customAxios = createAxiosInstance();
