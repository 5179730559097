import { Grid, Typography } from "@material-ui/core";
import { Question } from "../../../redux/interview/interview.types";

interface Props {
  questions: Question[];
  questionIndex: number;
  video: string;
}

export const ReviewerVideoPlayer = ({
  questions,
  questionIndex,
  video,
}: Props): JSX.Element => {
  return (
    <Grid item container justifyContent="center" spacing={2}>
      <Grid item xs={12}>
        <Typography variant="body1" align="center">
          Question #{questionIndex + 1}: {questions[questionIndex]["text"]}
        </Typography>
      </Grid>
      <Grid item>
        <video controls data-testid="video">
          <source data-testid="video-source" src={video} />
        </video>
      </Grid>
    </Grid>
  );
};
