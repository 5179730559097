/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { createSelector } from "@reduxjs/toolkit";
import { createAsyncStateSelector } from "../redux.helpers";
import { RootState } from "../root.reducer";

const selectInterviewState = (state: RootState) => state.interview;

export const selectInterviewStatus = createSelector(
  selectInterviewState,
  (state) => state.status,
);

export const selectQuestions = createSelector(
  selectInterviewState,
  (state) => state.questions,
);

export const selectCurrentQuestion = createSelector(
  selectInterviewState,
  (state) => state.currentQuestion,
);

export const selectInterviewError = createSelector(
  selectInterviewState,
  (interview) => interview.error,
);

export const selectInterviewAsyncState =
  createAsyncStateSelector(selectInterviewState);

export const selectUploadInProgress = createSelector(
  selectInterviewState,
  (state) => state.uploadCount > 0,
);

export const selectRecordingInProgress = createSelector(
  selectInterviewState,
  (state) => state.recordingInProgress,
);

export const selectBrowserIsInvalid = createSelector(
  selectInterviewState,
  (state) => state.browserIsInvalid,
);
