import { Redirect, Route, Switch } from "react-router";
import { ErrorBoundaryWrapper } from "./components/shared/ErrorBoundaryWrapper/ErrorBoundaryWrapper";
import { ProtectedRoute } from "./components/shared/ProtectedRoute/ProtectedRoute";
import InterviewPage from "./pages/interview/InterviewPage/InterviewPage";
import LoginPage from "./pages/reviewer/LoginPage/LoginPage";
import LogoutPage from "./pages/reviewer/LogoutPage/LogoutPage";
import ReviewApplicationPage from "./pages/reviewer/ReviewApplicationPage/ReviewApplicationPage";
import ReviewApplicationsPage from "./pages/reviewer/ReviewApplicationsPage/ReviewApplicationsPage";
import NotFoundErrorPage from "./pages/shared/NotFoundErrorPage/NotFoundErrorPage";

export const App = (): JSX.Element => {
  return (
    <ErrorBoundaryWrapper>
      <Switch>
        <Route exact path="/" component={InterviewPage} />
        <Route exact path="/reviewer/login" component={LoginPage} />
        <ProtectedRoute exact path="/reviewer/logout" component={LogoutPage} />
        <ProtectedRoute
          exact
          path="/reviewer"
          component={ReviewApplicationsPage}
        />
        <ProtectedRoute
          path="/reviewer/:id"
          component={ReviewApplicationPage}
        />
        <Route exact path="/not-found" component={NotFoundErrorPage} />
        <Redirect from="*" to="/not-found" />
      </Switch>
    </ErrorBoundaryWrapper>
  );
};
