import { Box, Container } from "@material-ui/core";
import { ReactChild, ReactFragment, ReactPortal } from "react";
import { Footer } from "../../../components/shared/Footer/Footer";
import { Header } from "../../../components/shared/Header/Header";

interface Props {
  children: boolean | ReactChild | ReactFragment | ReactPortal;
}

const WrapperPage = ({ children }: Props): JSX.Element => {
  return (
    <Box display="flex" flexDirection="column" height="100vh">
      <Header />
      <Container>{children}</Container>
      <Footer />
    </Box>
  );
};

export default WrapperPage;
