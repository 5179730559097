import { createSelector } from "@reduxjs/toolkit";
import { createAsyncStateSelector } from "../redux.helpers";
import { RootState } from "../root.reducer";

const selectAuthState = (state: RootState) => state.auth;

export const selectIsLoggedIn = createSelector(
  selectAuthState,
  (auth) => auth.isLoggedIn,
);

export const selectAuthAsyncStatus = createAsyncStateSelector(selectAuthState);
