import { MutableRefObject } from "react";
import Webcam from "react-webcam";

export interface ApplicantInfo {
  name: string;
  email: string;
}

export const interviewStatus = {
  new: 0,
  infoSubmitted: 1,
  ready: 2,
  inProgress: 3,
  complete: 4,
  error: -1,
} as const;
export type InterviewStatus =
  typeof interviewStatus[keyof typeof interviewStatus];

export interface Question {
  text: string;
  length: number;
}

export type WebcamRef = MutableRefObject<Webcam | null> | null;
