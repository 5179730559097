import { createStyles, makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formInput: {
      width: "24rem",
      marginBottom: theme.spacing(2),
      [theme.breakpoints.down("xs")]: {
        width: "95vw",
      },
    },
    submitButton: {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
      fontSize: theme.typography.h6.fontSize,
    },
  }),
);

export default useStyles;
