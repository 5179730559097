import { createAsyncThunk } from "@reduxjs/toolkit";
import { betaQuestions } from "../../data/beta-questions";
import { customAxios } from "../../utils/axios";
import { validateResponse } from "../action.helpers";
import { RootState } from "../root.reducer";
import { ApplicantInfo, Question } from "./interview.types";

// placeholder
export const getQuestions = createAsyncThunk<Question[]>(
  "interview/getQuestions",
  async () => betaQuestions,
);

export const submitForm = createAsyncThunk<string, ApplicantInfo>(
  "interview/doSubmitForm",
  async (info) => {
    const response = await customAxios.post("/interviews", { ...info });

    validateResponse(response, "id");

    return response.data.id as string;
  },
);

export const handleVideoData = createAsyncThunk<
  void,
  { questionIndex: number; data: Blob },
  { state: RootState }
>(
  "interview/doHandleVideoData",
  async ({ questionIndex, data }, { getState }) => {
    const {
      interview: { id },
    } = getState();

    if (!id) {
      throw new Error("failed to upload video");
    }

    const form = new FormData();

    form.append("video", data, "video");
    form.append("id", id);

    await customAxios.post(`/interviews/${id}/video/${questionIndex}`, form);
  },
);
