import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { isValidBrowser } from "../../utils/get-browser";
import {
  addAsyncMatchers,
  AsyncState,
  createMessageAction,
  defaultAsyncState,
} from "../redux.helpers";
import { getQuestions, handleVideoData, submitForm } from "./interview.actions";
import { InterviewStatus, interviewStatus, Question } from "./interview.types";

export interface InterviewState extends AsyncState {
  browserIsInvalid?: boolean;
  id?: string;
  status: InterviewStatus;
  error?: string;
  questions: Question[];
  currentQuestion: number;
  uploadCount: number;
  recordingInProgress: boolean;
}

export const initialInterviewState: InterviewState = {
  status: interviewStatus.new,
  questions: [],
  currentQuestion: 0,
  ...defaultAsyncState,
  uploadCount: 0,
  recordingInProgress: false,
};

const interviewSlice = createSlice({
  name: "interview",
  initialState: initialInterviewState,
  reducers: {
    setCurrentQuestion: (state, action: PayloadAction<number>) => {
      state.currentQuestion = action.payload;
    },
    setInterviewStatus: (state, action: PayloadAction<InterviewStatus>) => {
      state.status = action.payload;
    },
    setInterviewError: (state, action: PayloadAction<string | undefined>) => {
      state.status = interviewStatus.error;
      state.error = action.payload;
    },
    checkIfBrowserIsValid: (state) => {
      state.browserIsInvalid = !isValidBrowser();
    },
    resetInterviewState: () => {
      return initialInterviewState;
    },
    setRecordingInProgress: (state, action: PayloadAction<boolean>) => {
      state.recordingInProgress = action.payload;
    },
    // test only
    setUploadCount: (state, action: PayloadAction<number>) => {
      state.uploadCount = action.payload;
    },
    setQuestions: (state, action: PayloadAction<Question[]>) => {
      state.questions = action.payload;
    },
    setId: (state, action: PayloadAction<string | undefined>) => {
      state.id = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        submitForm.pending,
        createMessageAction("submitting info to video server"),
      )
      .addCase(submitForm.fulfilled, (state, action) => {
        state.id = action.payload;
        state.status = interviewStatus.infoSubmitted;
      })
      .addCase(submitForm.rejected, (state, action) => {
        state.status = interviewStatus.error;
        state.error = action.error.message;
      })
      .addCase(getQuestions.fulfilled, (state, action) => {
        state.questions = action.payload;
      })
      .addCase(handleVideoData.pending, (state) => {
        state.uploadCount++;
      })
      .addCase(handleVideoData.fulfilled, (state) => {
        state.uploadCount--;
      })
      .addCase(handleVideoData.rejected, (state) => {
        state.uploadCount--;
        state.status = interviewStatus.error;
        state.error = "Video upload failed!";
      });

    addAsyncMatchers(builder, "interview");
  },
});

export default interviewSlice.reducer;
export const {
  setCurrentQuestion,
  setInterviewStatus,
  setInterviewError,
  checkIfBrowserIsValid,
  resetInterviewState,
  setRecordingInProgress,
  setUploadCount,
  setQuestions,
  setId,
} = interviewSlice.actions;
