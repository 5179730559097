import { createTheme } from "@material-ui/core";
import { grey } from "@material-ui/core/colors";

export const theme = createTheme({
  palette: {
    background: {
      default: "#fffaf3",
    },
    primary: {
      main: "#745656",
      contrastText: "#fffaf3",
    },
    secondary: {
      main: "#5fc3b7",
      contrastText: "#ffffff",
    },
    text: {
      primary: "#745656",
      secondary: grey[900],
    },
  },
});
