import { Box, Container, Link, Typography } from "@material-ui/core";
import { CustomError } from "../../../utils/custom-error";
import useStyles from "./ErrorPage.styles";

interface Props {
  error: CustomError;
  resetError?: () => void;
}

const ErrorPage = ({ error }: Props): JSX.Element => {
  const styles = useStyles();

  return (
    <Container maxWidth="sm" data-testid="error-page">
      <Box textAlign="center">
        <Typography variant="h3" gutterBottom>
          Something went wrong!
        </Typography>
        <Box marginBottom="1.5rem">
          <Typography variant="h6" color="textSecondary">
            Error:{" "}
            {error.isVisible
              ? error.message
              : "Something went wrong during your interview!"}
          </Typography>
        </Box>
        <Box marginY="1.5rem">
          <Typography variant="h6">Please try the following steps:</Typography>
          <Typography variant="body1">
            <Link
              href="https://support.google.com/chrome/answer/95414"
              className={styles.link}>
              Update Google Chrome
            </Link>{" "}
            to the latest available version
          </Typography>
          <Typography variant="body1">
            <Link
              href="https://support.google.com/chrome/answer/2693767"
              className={styles.link}>
              Enable webcam and microphone access
            </Link>{" "}
            in Google Chrome
          </Typography>
          <Typography variant="body1">Disable adblocking extensions</Typography>
        </Box>
        <Typography variant="body2">
          If you are still unable to complete your interview after following the
          steps above, please email us at{" "}
          <a href="mailto:it@worldwidewings.co.jp">it@worldwidewings.co.jp</a>{" "}
          with a description of what caused your error and the time you
          encountered it.
        </Typography>
      </Box>
    </Container>
  );
};

export default ErrorPage;
