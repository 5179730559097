import { createSlice } from "@reduxjs/toolkit";
import {
  addAsyncMatchers,
  AsyncState,
  createMessageAction,
  defaultAsyncState,
  PayloadMessageAction,
  redirectAfterTimeout,
  resetGetStatus,
} from "../redux.helpers";
import {
  deleteApplication,
  getApplication,
  getApplications,
  updateApplication,
} from "./reviewer.actions";
import { Application } from "./reviewer.types";

export interface ReviewerState extends AsyncState {
  applications: Application[];
  currentApplication?: Application;
}

const initialState: ReviewerState = {
  applications: [],
  ...defaultAsyncState,
};

const reviewerSlice = createSlice({
  name: "reviewer",
  initialState,
  reducers: { resetGetStatus },
  extraReducers: (builder) => {
    builder
      .addCase(getApplications.pending, (state) => {
        delete state.currentApplication;
        state.doStatus = initialState.doStatus;
      })
      .addCase(getApplications.fulfilled, (state, action) => {
        state.applications = action.payload;
      })
      .addCase(getApplication.fulfilled, (state, action) => {
        state.currentApplication = action.payload;
      })
      .addCase(
        updateApplication.pending,
        createMessageAction("updating application"),
      )
      .addCase(
        updateApplication.fulfilled,
        (state, action: PayloadMessageAction<Application>) => {
          // prevent wiping out videos to keep signed urls
          // istanbul ignore next: type guard
          const videos = state.currentApplication?.videos || [];
          state.currentApplication = { ...action.payload, videos };
          action.meta.message = "updated application";
        },
      )
      .addCase(
        deleteApplication.pending,
        createMessageAction("deleting application"),
      )
      .addCase(deleteApplication.fulfilled, () => {
        redirectAfterTimeout("/reviewer")();
      });

    addAsyncMatchers(builder, "reviewer");
  },
});

export default reviewerSlice.reducer;
export const { resetGetStatus: resetReviewerGetStatus } = reviewerSlice.actions;
