import { useCallback, useRef } from "react";
import { useDispatch } from "react-redux";
import Webcam from "react-webcam";
import { handleVideoData } from "../redux/interview/interview.actions";
import {
  setInterviewError,
  setRecordingInProgress,
} from "../redux/interview/interview.slice";
import { VideoContext } from "./VideoContext";

interface Props {
  children: React.ReactNode;
  webcam?: Webcam;
}

export const VideoContextProvider = ({
  children,
  webcam,
}: Props): JSX.Element => {
  // the ref needs to be instantiated with null to work w/ react-webcam's type defs
  const webcamRef = useRef<Webcam>(webcam || null);
  const mediaRecorderRef = useRef<MediaRecorder>();

  const dispatch = useDispatch();

  const stopRecording = useCallback(() => {
    if (mediaRecorderRef.current?.state === "recording") {
      mediaRecorderRef.current.stop();
      dispatch(setRecordingInProgress(false));
    }
  }, [mediaRecorderRef, dispatch]);

  const startRecording = useCallback(
    (questionIndex: number) => {
      if (webcamRef?.current?.stream) {
        mediaRecorderRef.current = new MediaRecorder(webcamRef.current.stream, {
          mimeType: "video/webm",
        });

        mediaRecorderRef.current.ondataavailable = ({ data }) => {
          dispatch(handleVideoData({ questionIndex, data }));
        };

        mediaRecorderRef.current.onerror = (event) => {
          stopRecording();

          if (process.env.NODE_ENV === "development") {
            console.error(event);
          } else {
            dispatch(setInterviewError());
          }
        };

        mediaRecorderRef.current.start();

        dispatch(setRecordingInProgress(true));
      }
    },
    [webcamRef, mediaRecorderRef, dispatch, stopRecording],
  );

  return (
    <VideoContext.Provider
      value={{
        webcamRef,
        startRecording,
        stopRecording,
      }}>
      {children}
    </VideoContext.Provider>
  );
};
