import { Grid } from "@material-ui/core";
import { VideoHeader } from "../VideoHeader/VideoHeader";
import { WebcamArea } from "../WebcamArea/WebcamArea";

export const VideoArea = (): JSX.Element => {
  return (
    <Grid item>
      <VideoHeader />
      <WebcamArea />
    </Grid>
  );
};
