import { Box } from "@material-ui/core";
import { ErrorBoundary } from "@sentry/react";
import { ReactNode } from "react";
import ErrorPage from "../../../pages/shared/ErrorPage/ErrorPage";

interface FallbackProps {
  error: Error;
  resetError: () => void;
}

const errorPage = ({ error, resetError }: FallbackProps) => {
  return (
    <Box marginTop="1.5rem">
      <ErrorPage error={error} resetError={resetError} />
    </Box>
  );
};

interface Props {
  children: ReactNode;
}

export const ErrorBoundaryWrapper = ({ children }: Props): JSX.Element => {
  return <ErrorBoundary fallback={errorPage}>{children}</ErrorBoundary>;
};
