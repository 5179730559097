import { ElementType } from "react";
import { useSelector } from "react-redux";
import { Redirect, Route, RouteProps } from "react-router-dom";
import { selectIsLoggedIn } from "../../../redux/auth/auth.selectors";

type ProtectedRouteProps = RouteProps & {
  component: ElementType;
};

export const ProtectedRoute = ({
  component: Component,
  ...rest
}: ProtectedRouteProps): JSX.Element => {
  const isLoggedIn = useSelector(selectIsLoggedIn);
  return (
    <Route
      {...rest}
      render={(props) =>
        isLoggedIn ? (
          <Component {...props} />
        ) : (
          <Redirect to="/reviewer/login" />
        )
      }
    />
  );
};
