import { createStyles, makeStyles, Theme } from "@material-ui/core";
import {
  interviewStatus,
  InterviewStatus,
} from "../../../redux/interview/interview.types";

interface Props {
  recordingInProgress: boolean;
  status: InterviewStatus;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    header: {
      color: ({ recordingInProgress, status }: Props) => {
        if (recordingInProgress) {
          return theme.palette.info.main;
        }
        if (status === interviewStatus.inProgress) {
          return theme.palette.warning.main;
        }
        if (status === interviewStatus.complete) {
          return theme.palette.success.main;
        }
        return theme.palette.text.secondary;
      },
    },
  }),
);

export default useStyles;
