import { Button, Grid, Link, TextField } from "@material-ui/core";
import { useEffect, useMemo } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { RouteComponentProps } from "react-router";
import { Link as RouterLink } from "react-router-dom";
import { ApplicationInfoCard } from "../../../components/reviewer/ApplicationInfoCard/ApplicationInfoCard";
import { ReviewerVideoPlayer } from "../../../components/reviewer/ReviewerVideoPlayer/ReviewerVideoPlayer";
import { ProgressAlerts } from "../../../components/shared/ProgressAlerts/ProgressAlerts";
import { ProgressPopup } from "../../../components/shared/ProgressPopup/ProgressPopup";
import { betaQuestions } from "../../../data/beta-questions";
import {
  getApplication,
  updateApplication,
} from "../../../redux/reviewer/reviewer.actions";
import {
  selectCurrentApplication,
  selectReviewerAsyncState,
} from "../../../redux/reviewer/reviewer.selectors";
import SpinnerPage from "../../shared/SpinnerPage/SpinnerPage";
import WrapperPage from "../../shared/WrapperPage/WrapperPage";

type Props = RouteComponentProps<{ id: string }>;

interface FormProps {
  comments: string;
}

const ReviewApplicationPage = ({ match }: Props): JSX.Element => {
  const { id } = match.params;

  const dispatch = useDispatch();

  const { doStatus, getStatus } = useSelector(selectReviewerAsyncState);
  const application = useSelector(selectCurrentApplication);

  const defaultValues = useMemo<FormProps>(
    () => ({
      comments: application?.comments || "",
    }),
    [application?.comments],
  );

  const {
    register,
    handleSubmit,
    reset,
    formState: { isDirty },
  } = useForm({ defaultValues });

  useEffect(() => {
    dispatch(getApplication(id));
  }, [dispatch, id]);

  useEffect(() => {
    reset({ comments: defaultValues.comments }, { keepDirty: false });
  }, [reset, defaultValues.comments]);

  const onSubmit = ({ comments }: FormProps) => {
    dispatch(updateApplication({ id, update: { comments } }));
  };

  return (
    <WrapperPage>
      <Grid container justifyContent="center" spacing={2}>
        <Grid item>
          <Link component={RouterLink} to="/reviewer" variant="h5">
            All Applications
          </Link>
        </Grid>
        <ProgressAlerts status={getStatus} />
        {!application && <SpinnerPage />}
        {application && (
          <Grid item container justifyContent="center" spacing={2}>
            <ApplicationInfoCard application={application} />
            {application.videos.map(({ questionIndex, video }) => (
              <ReviewerVideoPlayer
                key={video}
                // NOTE: replace with individual applicant's questions in the future
                questions={betaQuestions}
                questionIndex={questionIndex}
                video={video}
              />
            ))}
            <Grid item xs={12}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={2} justifyContent="center">
                  <Grid item xs={12}>
                    <TextField
                      {...register("comments")}
                      label="Comments"
                      type="text"
                      variant="outlined"
                      fullWidth
                      multiline
                      rows={10}
                      inputProps={{ "aria-label": "comments" }}
                    />
                  </Grid>
                  <Grid item>
                    <Button
                      type="submit"
                      variant="contained"
                      color="secondary"
                      disabled={!isDirty}>
                      submit
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </Grid>
          </Grid>
        )}
      </Grid>
      <ProgressPopup status={doStatus} />
    </WrapperPage>
  );
};

export default ReviewApplicationPage;
