import { AxiosResponse } from "axios";
import flat from "flat";
import { pull } from "lodash";

export const validateResponse = (
  response: AxiosResponse,
  keys: string[] | string,
): void => {
  if (typeof keys === "string") {
    keys = [keys];
  }
  const notFoundKeys: string[] = [];
  // handle simple key names (e.g. "user")
  keys.forEach((key) => {
    if (!response.data[key]) {
      notFoundKeys.push(key);
    }
  });

  // handle nested key names (e.g. "payload.token")
  const flattenedData: Record<string, unknown> = flat(response.data);
  [...notFoundKeys].forEach((key) => {
    if (flattenedData[key]) {
      pull(notFoundKeys, key);
    } else if (process.env.NODE_ENV === "development") {
      console.error(`Expected key "${key}" in response`, response);
    }
  });

  if (notFoundKeys.length > 0) {
    throw new Error("requested data not found");
  }
};
