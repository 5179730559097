import { Box, Typography } from "@material-ui/core";

export const ApplicantInfoInstructions = (): JSX.Element => {
  return (
    <Box mb={2} pl={1} pr={1}>
      <Typography variant="body1" align="center">
        When ready, please click the <strong>Next</strong> button below.
      </Typography>
      <Typography variant="body1" align="center">
        <strong>Please allow access to webcam when asked.</strong>
      </Typography>
      <Typography variant="body1" align="center">
        <strong>(Otherwise, you will not be able to continue!)</strong>
      </Typography>
    </Box>
  );
};
